@charset "UTF-8";
/**
 * Setting
 * -----------------------------------------------------------------------------
 */
/**
 * Mixin
 * -----------------------------------------------------------------------------
 */
/**
 * Reset
 * -----------------------------------------------------------------------------
 */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
menu,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background-color: transparent; }

body {
  line-height: 1; }

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

/*-------------------------------------------------
	Form
-------------------------------------------------*/
input,
button,
textarea {
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  border-radius: 0; }

select {
  outline: 0;
  border-radius: 0; }

input,
textarea {
  padding: 0;
  background-color: inherit;
  border: none; }

/*-------------------------------------------------
	Others
-------------------------------------------------*/
iframe[name='google_conversion_frame'] {
  height: 0 !important;
  width: 0 !important;
  line-height: 0 !important;
  font-size: 0 !important;
  margin-top: -13px; }

/**
 * Basic : Styles for Tags
 * -----------------------------------------------------------------------------
 */
noscript p {
  font-size: 20px;
  line-height: 1.5;
  color: red; }

/*-------------------------------------------------
	Base
-------------------------------------------------*/
html,
body {
  min-height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-feature-setting: 'palt';
  position: relative;
  font-family: "Cardo", "Shippori Mincho", serif;
  opacity: 0;
  transition: opacity 1000ms cubic-bezier(0.39, 0.575, 0.565, 1);
  color: #1F3426;
  box-sizing: border-box; }
  body.is-load {
    opacity: 1; }
  body.is-fixed {
    position: fixed;
    left: 0;
    width: 100%; }

.clearfix:after {
  height: 0;
  visibility: hidden;
  content: '.';
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  overflow: hidden; }

.clearfix {
  _height: 1px;
  min-height: 1px;
  /*・･*/
  /*/
height: auto;
overflow: hidden;
/**/ }

.show_pc {
  display: block; }
  @media only screen and (max-width: 768px) {
    .show_pc {
      display: none; } }

.show_sp {
  display: none; }
  @media only screen and (max-width: 768px) {
    .show_sp {
      display: block; } }

.show_sp_inline {
  display: none; }
  @media only screen and (max-width: 768px) {
    .show_sp_inline {
      display: inline; } }

/*----- フェードインしながら上へスライド -----*/
.fadeinup {
  opacity: 0;
  -webkit-transform: translate(0, 60px);
          transform: translate(0, 60px);
  transition: 1000ms ease; }
  @media only screen and (max-width: 768px) {
    .fadeinup {
      -webkit-transform: translate(0, 40px);
              transform: translate(0, 40px); } }

.fadeInUp {
  opacity: 1.0;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

table {
  border-collapse: collapse;
  border-spacing: 0; }

::selection {
  background: #eee; }

::-moz-selection {
  background: #eee; }

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fff inset; }

input,
textarea {
  font-family: "Cardo", "Shippori Mincho", serif; }

a {
  color: #1F3426;
  text-decoration: none;
  transition: opacity 500ms; }
  a:visited {
    color: #1F3426; }
  @media only screen and (min-width: 320px) {
    a:hover {
      opacity: 0.5; } }

img {
  -webkit-backface-visibility: hidden; }

button,
input[type='submit'] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Cardo", "Shippori Mincho", serif; }

/* original select */
label.original_select,
label.original_select * {
  font-family: YakuHanJP, sans-serif; }

/* labelタグのスタイル */
label.original_select {
  position: relative;
  display: inline-block;
  background: #fff;
  font-size: 1em;
  overflow: hidden;
  z-index: 0;
  border: solid 1px #e8e8e8;
  border-radius: 26px; }

/* 下三角の表示 */
label.original_select:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  right: 17px;
  margin-top: -0.2em;
  border-width: 0.45em 0.3em;
  border-style: solid;
  border-color: #cdcdcd transparent transparent;
  z-index: -1; }

/* selectタグのスタイル */
label.original_select select {
  width: 94%;
  height: auto;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  padding: 0.4em 3em 0.4em 1.5em;
  margin: 0;
  color: #727272;
  background: transparent;
  border: none;
  border-radius: 0;
  font-size: 1em;
  outline: none;
  font-family: "Cardo", "Shippori Mincho", serif; }

/* selectタグ選択中のスタイル */
/* IEの下三角非表示 */
label.original_select select::-ms-expand {
  display: none; }

/* Firefoxの点線非表示 */
label.original_select select::-moz-focus-inner {
  border: 0; }

/* 表示されるオプションスタイル */
label.original_select select * {
  background: #fff;
  color: #727272;
  text-shadow: none; }

h1, h2, h3, h4, h5 {
  font-weight: normal; }

img {
  display: block; }

::selection {
  background: #dae3da; }

/**
 * module
 * -----------------------------------------------------------------------------
 */
.MainContants {
  padding: 200px 0 16px; }
  @media only screen and (max-width: 768px) {
    .MainContants {
      padding-top: 132px; } }

.pageTitle {
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.075em;
  text-align: center; }

.breadcrumb {
  width: 95.55556%;
  margin: 0 auto;
  padding-top: 118px; }
  .page-template-page-about .breadcrumb {
    padding-top: 200px; }
    @media only screen and (max-width: 768px) {
      .page-template-page-about .breadcrumb {
        padding-top: 118px; } }
  @media only screen and (max-width: 768px) {
    .breadcrumb {
      width: 89.33333%; } }
  .breadcrumb a, .breadcrumb span {
    font-size: 10px;
    line-height: 1.75;
    letter-spacing: 0.01em;
    color: #637269;
    position: relative; }
    .breadcrumb a:not(:first-child), .breadcrumb span:not(:first-child) {
      padding-left: 20px; }
      .breadcrumb a:not(:first-child)::before, .breadcrumb span:not(:first-child)::before {
        content: ">";
        position: absolute;
        top: -1px;
        left: 7px;
        bottom: 0;
        margin: auto 0; }

/**
 * header
 * -----------------------------------------------------------------------------
 */
.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0 32px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff; }
  @media only screen and (max-width: 768px) {
    .Header {
      padding-left: 20px;
      padding-right: 20px; } }
  .Header.is-noBackground {
    background-color: transparent; }

.GlobalHeader {
  height: 74px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  @media only screen and (max-width: 768px) {
    .GlobalHeader {
      height: 60px; } }
  .GlobalHeader__logoImg {
    width: 68px;
    height: 16.75px;
    background-image: url(/cms/wp-content/themes/moss/assets/images/common/logo.svg);
    background-size: 68px auto;
    background-repeat: no-repeat;
    display: block; }
    .is-noBackground .GlobalHeader__logoImg {
      background-image: url(/cms/wp-content/themes/moss/assets/images/common/logo_white.svg); }
  .GlobalHeader__col {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .GlobalHeader__contact {
    padding-right: 60px; }
    @media only screen and (max-width: 768px) {
      .GlobalHeader__contact {
        padding-right: 54px; } }
    .GlobalHeader__contact > a {
      font-size: 13px;
      line-height: 1;
      letter-spacing: 0.075em; }
      .is-noBackground .GlobalHeader__contact > a {
        color: #fff; }
  .GlobalHeader__nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: opacity 800ms ease 0ms, visibility 0ms 800ms, z-index 0ms 800ms;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .GlobalHeader__nav::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.6;
      z-index: 1; }
    .GlobalHeader__nav.is-open {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      transition: opacity 800ms ease 100ms, visibility 0ms 0ms, z-index 0ms 0ms; }
    .GlobalHeader__nav > .inner {
      background-color: #EDEFEE;
      width: 360px;
      height: 100%;
      padding-left: 120px;
      box-sizing: border-box;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      box-sizing: border-box;
      overflow: scroll;
      z-index: 2;
      position: relative; }
      @media only screen and (max-width: 768px) {
        .GlobalHeader__nav > .inner {
          width: 100%;
          padding-left: 45px; } }
      .GlobalHeader__nav > .inner > .inner {
        padding: 20px 0;
        margin: auto 0; }
  .GlobalHeader__navItem:not(:first-child) {
    margin-top: 36px; }
  .GlobalHeader__navLink {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.075em; }
  .GlobalHeader__underNav {
    margin-top: 30px;
    padding-left: 16px;
    border-left: 1px solid #E2E6E4; }
  .GlobalHeader__underNavItem:not(:first-child) {
    margin-top: 28px; }
  .GlobalHeader__underNavLink {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.075em; }
  .GlobalHeader__sns {
    margin-top: 32px; }
  .GlobalHeader__snsItem:not(:first-child) {
    margin-top: 32px; }
  .GlobalHeader__snsLink {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.075em; }

_:lang(x) + _:-webkit-full-screen-document, .GlobalHeader__contact {
  position: relative;
  top: 1px; }

.HamburgerWrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 1;
  transition: opacity ease 500ms;
  padding: 10px;
  cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .HamburgerWrapper {
      right: 10px; } }

.Hamburger,
.Hamburger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box; }

.Hamburger {
  position: relative;
  width: 30px;
  height: 8px;
  display: block; }

.Hamburger span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #1F3426; }
  .is-noBackground .Hamburger span {
    background-color: #fff; }
  .is-openMenu .Hamburger span {
    background-color: #1F3426; }

.Hamburger span:nth-of-type(1) {
  top: 0; }

.Hamburger span:nth-of-type(2) {
  bottom: 0; }

.Hamburger.is-on span:nth-of-type(1) {
  -webkit-animation: menu-bar01 0.75s forwards;
          animation: menu-bar01 0.75s forwards; }

.Hamburger.is-on span:nth-of-type(2) {
  -webkit-animation: menu-bar02 0.75s forwards;
          animation: menu-bar02 0.75s forwards; }

@-webkit-keyframes menu-bar01 {
  0% {
    -webkit-transform: translateY(3.5px) rotate(17deg);
            transform: translateY(3.5px) rotate(17deg); }
  50% {
    -webkit-transform: translateY(3.5px) rotate(0);
            transform: translateY(3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); } }

@keyframes menu-bar01 {
  0% {
    -webkit-transform: translateY(3.5px) rotate(17deg);
            transform: translateY(3.5px) rotate(17deg); }
  50% {
    -webkit-transform: translateY(3.5px) rotate(0);
            transform: translateY(3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); } }

@-webkit-keyframes menu-bar02 {
  0% {
    -webkit-transform: translateY(-3.5px) rotate(-17deg);
            transform: translateY(-3.5px) rotate(-17deg); }
  50% {
    -webkit-transform: translateY(-3.5px) rotate(0);
            transform: translateY(-3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); } }

@keyframes menu-bar02 {
  0% {
    -webkit-transform: translateY(-3.5px) rotate(-17deg);
            transform: translateY(-3.5px) rotate(-17deg); }
  50% {
    -webkit-transform: translateY(-3.5px) rotate(0);
            transform: translateY(-3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); } }

.Hamburger.is-active.is-on span:nth-of-type(1) {
  width: 100%;
  -webkit-animation: active-menu-bar01 0.75s forwards;
          animation: active-menu-bar01 0.75s forwards; }

@-webkit-keyframes active-menu-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(3.5px) rotate(0);
            transform: translateY(3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(3.5px) rotate(17deg);
            transform: translateY(3.5px) rotate(17deg); } }

@keyframes active-menu-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(3.5px) rotate(0);
            transform: translateY(3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(3.5px) rotate(17deg);
            transform: translateY(3.5px) rotate(17deg); } }

.Hamburger.is-active.is-on span:nth-of-type(2) {
  -webkit-animation: active-menu-bar02 0.75s forwards;
          animation: active-menu-bar02 0.75s forwards; }

@-webkit-keyframes active-menu-bar02 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(-3.5px) rotate(0);
            transform: translateY(-3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(-3.5px) rotate(-17deg);
            transform: translateY(-3.5px) rotate(-17deg); } }

@keyframes active-menu-bar02 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(-3.5px) rotate(0);
            transform: translateY(-3.5px) rotate(0); }
  100% {
    -webkit-transform: translateY(-3.5px) rotate(-17deg);
            transform: translateY(-3.5px) rotate(-17deg); } }

/**
 * Footer
 * -----------------------------------------------------------------------------
 */
.Footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 98px;
  background-color: #fff;
  border-top: 1px solid #E2E6E4;
  box-sizing: border-box; }
  @media only screen and (max-width: 768px) {
    .Footer {
      height: 83px;
      padding: 0; } }
  .Footer > .inner {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: end;
            align-items: end;
    width: 95.55556%;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      .Footer > .inner {
        width: 89.33333%;
        margin: 0 auto; } }
  .Footer__information {
    font-size: 11px;
    line-height: 1.75;
    letter-spacing: 0.05em; }
  .Footer__copy {
    font-size: 10px;
    line-height: 1.925;
    letter-spacing: 0.05em; }
